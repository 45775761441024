* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Kyiv*Type Sans";
  src: url("styles/KyivTypeSans-VarGX.ttf") format("truetype");
}

h1 {
  font-family: "Kyiv*Type Sans";
}

p {
  font-family: Lexend;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
}

.react-datepicker__day--selected {
  background-color: #f04d23 !important;
  border-radius: 99999px !important;
}

.react-datepicker__day--today {
  background-color: #f04d23 !important;
  border-radius: 99999px !important;
}

.react-datepicker.calendar-className {
  border-radius: 8px !important;
  border: 2px solid #262757 !important;
  background: #fff !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.sc-gMXBao.loAVqq.css-b62m3t-container {
  color: #262757 !important;
  font-family: Lexend !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 34px !important;
}

.sc-hJdEdJ.jPJsSe.css-b62m3t-container {
  color: #262757 !important;
  font-family: Lexend !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 34px !important;
}
